<template>
    <div>
        <div>
            <h1>Utilisateurs supprimés</h1>
        </div>
        <br>
        <a-spin :spinning="processing">
            <a-table
                    bordered
                    v-if="users.length"
                    :dataSource="users"
                    :columns="columns"
                    :rowKey="
            (record) => {
              return record.id;
            }
          "
            >
                <template slot="row_index" slot-scope="text, record, index">
                    <p>{{ index + 1 }}</p>
                </template>
                <template slot="etat" slot-scope="text, record">
                    <EtatInscriptionIndicator :etat-inscription="record.etat"/>
                </template>
                <template slot="secteur_activite" slot-scope="text, record">
                    {{get(record, 'etat_activites[0].secteur.nom_fr')}}
                </template>
                <template slot="wilaya_professionnelle" slot-scope="text, record">
                    {{ get(record, "wilaya_professionnelle.nom_fr") }}
                </template>

                <template
                        class="table-operation"
                        slot="operation"
                        slot-scope="text, record"
                >
                    <a-popconfirm
                            title="Etes-vous sûr de vouloir restaurer ce utilisateur ?"
                            @confirm="() => onRestore(record.id)"
                    >
                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Restaurer</span>
                            </template>
                            <a-icon
                                    type="up-circle"
                                    class="action_icons"
                                    theme="twoTone"
                            />
                        </a-tooltip>
                    </a-popconfirm>
                    <a-popconfirm

                            title="Etes-vous sûr de vouloir supprimer définitevement ?"
                            @confirm="() => onDelete(record.id)"
                    >
                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Suppression</span>
                            </template>
                            <a-icon
                                    type="delete"
                                    class="action_icons"
                                    theme="twoTone"
                                    twoToneColor="#eb2f96"
                            />
                        </a-tooltip>
                    </a-popconfirm>
                </template>
                <template slot="statut_prof" slot-scope="text, record">
                    <StatuProfessionalIndicator
                            :statut-professionnel="
                getStatuProfessionnel(record.etat_activites)
              "
                    />
                </template>
                <template slot="nom_prenom" slot-scope="text, record">
                    {{ record.nom_fr }}
                    {{ record.prenom_fr }}
                </template>
                <!-- _________________________START___MATRICULE-->
                <template slot="matricule" slot-scope="text, record">
            <span v-bind:class="{ black: isNewUser(record) }">
              <a-badge v-if="isNewUser(record)" status="processing"/>
              {{ record.matricule }}
            </span>
                </template>

            </a-table>
            <EmptyResult v-else/>
        </a-spin>
    </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import EmptyResult from "@/components/common/EmptyResult";
  import EtatInscriptionIndicator from "@/components/common/EtatInscriptionIndicator";
  import StatuProfessionalIndicator from "@/components/common/StatuProfessionalIndicator";

  const columns = [
    {
      title: "N°",
      scopedSlots: {customRender: "row_index"},
    },
    {
      key: 'matricule',
      title: 'Matricule',
      dataIndex: 'matricule'
    },
    {
            key: "nom_fr",
            title: "Nom & Prénom",
            scopedSlots: {customRender: "nom_prenom"},
        },
        {
            title: "Secteur activité",
            scopedSlots: {customRender: "secteur_activite"},
        },
        {
            title: "Wilaya Pro.",
            scopedSlots: {customRender: "wilaya_professionnelle"},
        },
        {
            title: "Etat",
            scopedSlots: {customRender: "etat"},
        },
        {
            title: "Statut professionnel",
            scopedSlots: {customRender: "statut_prof"},
        },
        {
            title: "Actions",
            scopedSlots: {customRender: "operation"},
        },
    ];

    export default {
        name: "DeletedUserIndex",
        components: {
            EmptyResult,
            EtatInscriptionIndicator,
            StatuProfessionalIndicator,
        },
        created() {
          this.getData()
            .finally(() => this.stopProcessing())
        },
        data() {
            return {
              processing: true,
              columns
            };
        },
        computed: {
            ...mapState({users: (state) => state.adherents.deleted_users})
        },
        methods: {
          startProcessing() {
            this.processing = true;
          },
          stopProcessing() {
            this.processing = false;
          },
          onRestore(userID) {
            this.startProcessing();
            this.restore(userID)
              .then(() => this.getData())
              .finally(() => this.stopProcessing());
          },
          onDelete(userID) {
            this.startProcessing();
            this.delete(userID)
              .then(() => this.getData())
              .finally(() => this.stopProcessing());
            },
            getStatuProfessionnel(etatActivites) {
              return this.get(etatActivites, '[0].statut_professionnel');
            },
            getSecteurActvite(etatActivites) {
              return this.get(etatActivites, '[0].statut_professionnel');
            },
            ...mapActions({
                getData: "fetchDeletedusers",
                restore: "restoreUser",
                delete: "destroyUserDefinitely",
            }),
        }
    }
</script>

<style scoped>
    .action_icons {
        margin: 4px;
        font-size: large;
    }
</style>
